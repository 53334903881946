@font-face {
  font-family: "WorkhackFont";
  src: url("../fonts/InterVariable.ttf") format("truetype");
  font-weight: 100 1000;
}

:root {
  --color-text-purple: #714CD3 !important;
  --color-text-primary: #344563 !important;
  --color-text-subdued: #6B778C !important;
}
