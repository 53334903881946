.min-height-80vh {
    min-height: 80vh;
}

.min-height-100vh {
    min-height: 100vh;
}

/* flex */
.flex {
    display: flex;
}

.flex-align-center {
    align-items: center;
}

.gap-1 {
    gap: 1rem;
}

.gap-2 {
    gap: 2rem;
}

.gap-3 {
    gap: 3rem;
}

.gap-4 {
    gap: 4rem;
}

.gap-5 {
    gap: 5rem;
}

.flex-dir-col {
    flex-direction: column;
}

.flex-justify-center {
    justify-content: center;
}

.flex-align-center {
    align-items: center;
}

.flex-justify-evenly {
    justify-content: space-evenly;
}

.flex-justify-between {
    justify-content: space-between;
}

.flex-justify-evenly {
    justify-content: space-evenly;
}

.flex-justify-start {
    justify-content: start;
}

.flex-justify-end {
    justify-content: end;
}

.flex-justify-around {
    justify-content: space-around;
}

.flex-align-start {
    align-items: flex-start;
}

.flex-wrap {
    flex-wrap: wrap;
}

/* fonts */

.fs-10px {
    font-size: 10px;
}

.fs-12px {
    font-size: 12px;
}

.fs-14px {
    font-size: 14px;
}

.fs-16px {
    font-size: 16px;
}

.fs-18px {
    font-size: 18px;
}

.fs-20px {
    font-size: 20px;
}

.fs-22px {
    font-size: 22px;
}

.fs-24px {
    font-size: 24px;
}

.fs-26px {
    font-size: 26px;
}

.fs-28px {
    font-size: 28px;
}

.fs-30px {
    font-size: 30px;
}

.fs-32px {
    font-size: 32px;
}

.fs-34px {
    font-size: 34px;
}

.fs-36px {
    font-size: 36px;
}

/* font-weight */

.fw-700 {
    font-weight: 700;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-900 {
    font-weight: 900;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

/* text align */
.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.text-align-left {
    text-align: left;
}

/* padding */
.pad-1 {
    padding: 1rem;
}

/* family */

.font-abeezee {
    font-family: 'ABeeZee', sans-serif;
}

.font-Ubuntu {
    font-family: 'Ubuntu', sans-serif;
}

.font-Rubik {
    font-family: 'Rubik', sans-serif;
}

.large-number {
    font-size: 72px;
    font-weight: 500;
}

/* grid */
.grid {
    display: grid;
}

.grid-col-50-50 {
    grid-template-columns: 1fr 1fr;
}

/* margin-top */
.mt-20 {
    margin-top: 20px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-24 {
    margin-top: 24px;
}

.m-x-auto {
    margin-inline: auto;
}

.m-l-auto {
    margin-left: auto;
}

.relative {
    position: relative;
}

/* opacity */
.op-50 {
    opacity: .5;
}

.op-75 {
    opacity: .75;
}

/* width */
.width-100 {
    width: 100%;
}

/* buttons */
.primary-btn {
    display: block;
    background: var(--primary);
    box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px rgba(174, 174, 192, 0.4);
    border-radius: 10px;
    border: transparent;
    font-size: 16px;
    font-weight: 600;
    padding: .5rem 1rem;
    overflow: hidden;
    text-align: center;
    color: var(--white);
    position: relative;
    transition: 0.2s transform ease-in-out;
    will-change: transform;
    z-index: 0;
}

.primary-btn::after {
    background-color: var(--white);
    border-radius: 10px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-out;
    will-change: transform;
    z-index: -1;
}

.primary-btn:hover::after {
    transform: translate(0, 0);
}

.primary-btn:hover {
    border: 2px solid var(--primary);
    color: var(--primary);
    /* transform: scale(1.05); */
    will-change: transform;
}

















.secondary-btn {
    padding: .5rem 1rem;
    max-width: 200px;
    width: 100%;
    cursor: pointer;
    border: transparent;
    background: #F0F0F3;
    box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px rgba(174, 174, 192, 0.4);
    border-radius: 100px;
    color: var(--primary);
    text-align: center;
}

/* floating btn */

.video-ananalysis-btn {
    position: fixed;
    display: flex;
    align-items: center;
    bottom: 5%;
    justify-content: center;
    right: 5%;
    background: var(--primary);
    color: white;
    padding: 5px 16px 10px 16px;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
}

/* heading */
.heading-lg {
    color: var(--primary);
    font-weight: 700;
    text-align: center;
    -webkit-text-stroke: 1px black;
    font-size: 4rem;
}

/* pointer */
.pointer {
    cursor: pointer;
}