@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

:root {
  --primary: #75447e;
  --light: #f3e5ff;
  --white: #fff;
  --black: #000;
  --light-white: #f0f0f3;
  --grey: #c8c8c8;
}

a {
  color: inherit !important;
}

.flex {
  display: flex;
}

.flex-align-center {
  align-items: center;
}

.Button-round {
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: -10px 2px;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: -10px -10px 30px #ffffff, 10px 10px 30px rgba(174, 174, 192, 0.4);
}

.Button-round:hover {
  background-color: #ffffff;
}

input:focus {
  outline: 0;
  border: 0px;
}

.ant-checkbox {
  background-color: #f0f0f3 !important;
}

.ant-checkbox-input {
  background-color: #f0f0f3 !important;
}

.ant-checkbox-inner {
  background-color: #f0f0f3 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.ant-checkbox-checked::after {
  border-color: #ffffff !important;
  color: #000000 !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #693372 !important;
}

.table-column-title {
  color: #693372;
}

.csvdownload {
  width: 190px;
  height: 26px;
  box-shadow: -10px -10px 30px #ffffff, 10px 10px 30px rgba(174, 174, 192, 0.4);
  border: 0px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}

.csvdownload:hover {
  background-color: #693372;
  border: 1px solid #693372;
  color: #ffffff;
}