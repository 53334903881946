@import url("./utils.css");
@import url("./components/App.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --rdp-accent-color: #714cd3 !important;
}

.Button-round {
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: -10px 2px;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: -10px -10px 30px #ffffff, 10px 10px 30px rgba(174, 174, 192, 0.4);
}

.Button-round:hover {
  background-color: #ffffff;
}

input:focus {
  outline: 0;
  border: 0px;
}

.ant-checkbox {
  background-color: #f0f0f3 !important;
}

.ant-checkbox-input {
  background-color: #f0f0f3 !important;
}

.ant-checkbox-inner {
  background-color: #f0f0f3 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.ant-checkbox-checked::after {
  border-color: #ffffff !important;
  color: #000000 !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #693372 !important;
}

.table-column-title {
  color: #693372;
}

.csvdownload {
  width: 190px;
  height: 26px;
  box-shadow: -10px -10px 30px #ffffff, 10px 10px 30px rgba(174, 174, 192, 0.4);
  border: 0px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}

.csvdownload:hover {
  background-color: #693372;
  border: 1px solid #693372;
  color: #ffffff;
}

.csvdownload.selected-downnload {
  background-color: #693372;
  border: 1px solid #693372;
  color: #ffffff;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  gap: 5rem;
}

.ant-list-item {
  border-bottom: none !important;
}

.summary-box {
  flex-wrap: wrap !important;
}

.campaign-list-item {
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12);
  margin-bottom: 1rem;
}

.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  animation: 1s blink ease infinite !important;
}

.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.mesh {
  background-color: #ff99af;
  background-image: radial-gradient(
      at 87% 47%,
      hsla(33, 65%, 75%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 61% 33%, #c260d3 0px, transparent 50%),
    radial-gradient(at 90% 4%, hsla(259, 72%, 70%, 1) 0px, transparent 50%),
    radial-gradient(at 25% 71%, hsla(316, 77%, 64%, 1) 0px, transparent 50%);
}

.ant-input,
.chakra-input,
.chakra-textarea {
  background-color: #f8f9f9 !important;
  border-radius: 0.375rem !important;
}

.dnd-line {
  border-bottom: solid 4px rgba(45, 170, 219, 0.3) !important;
}

.dot-flashing {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #1da1f1;
  color: #1da1f1;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -12px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #1da1f1;
  color: #1da1f1;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 12px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #1da1f1;
  color: #1da1f1;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #1da1f1;
  }
  50%,
  100% {
    background-color: #c5e5f8;
  }
}

.infinite-scroll-component__outerdiv {
  margin: 0 !important;
}

.ScrollAreaRoot {
  width: 100%;
  height: 225px;
  border-radius: 4px;
  overflow: hidden;
  background-color: white;
  --scrollbar-size: 10px;
}

.ScrollAreaViewport {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.ScrollAreaScrollbar {
  display: flex;
  /* ensures no selection */
  user-select: none;
  /* disable browser handling of all panning and zooming gestures on touch devices */
  touch-action: none;
  padding: 2px;
  transition: background 160ms ease-out;
}

.ScrollAreaScrollbar[data-orientation="vertical"] {
  width: var(--scrollbar-size);
}
.ScrollAreaScrollbar[data-orientation="horizontal"] {
  flex-direction: column;
  height: var(--scrollbar-size);
}

.ScrollAreaThumb {
  flex: 1;
  border-radius: var(--scrollbar-size);
  position: relative;
}
/* increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html */
.ScrollAreaThumb::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  min-width: 44px;
  min-height: 44px;
}

.ScrollAreaCorner {
  background: var(--chakra-colors-black-50);
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.rdp-day_selected {
  background-color: #714cd3 !important;
}
